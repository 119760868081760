import $ from "jquery";

export const locallyScript = () => {
  $(document).ready(function () {

    let company_domain = $("#company_domain").val();
    let company_name = $("#company_name").val();
    let company_id = $("#company_id").val();
    // let company_name_slug = $("#company_name_slug").val();
    // let country_code = $("#country_code").val();
    // let company_map = $("#company_map").val();
    const lang = $("#language-code").val();
    const upc = $("#upc").val();    
    const locally_id = $('.comapy-locally-id').val();
    const gpl_or_smu = $('.gpl_or_smu').val();

    if(company_domain && company_name && company_id && lang && upc && gpl_or_smu == 'GPL' && locally_id ) {

      let lcly_config_0 = {
        company_name: company_name,
        show_dealers: "2",
        n_dealers: "2",
        button_text:
          lang === "fr-ca"
            ? "Trouver d&apos;autres d&eacute;taillants"
            : "Find more retailers",
        button_id: "HTML",
        company_id: company_id,

        upc: upc,
        css: "6",
        show_location_switcher: "0",
        show_location_prompt: "1",
        lang: lang,
        show_directions: "1",
        no_link: "1",
      };

      // get from the page the value of a potential specific category (defined in the admin). Allows locally to apply different rules
      const locally_category = $('.locally_category').val() || null;

      if (locally_category) {
        lcly_config_0.category = locally_category;
      }

    let lcly_query_0 = Object.keys(lcly_config_0)
      .reduce(function (a, k) {
        a.push(
          encodeURIComponent(k) + "=" + encodeURIComponent(lcly_config_0[k])
        );
        return a;
      }, [])
      .join("&");
    let lcly_endpoint_0 = `https://${company_domain}.locally.com/stores/map.js?'${lcly_query_0}`;

    console.log("lcly_endpoint_0: " + lcly_endpoint_0);

    const newLocallyScript = $('<script id="lcly-script-0" async></script>');

    newLocallyScript.attr("src", lcly_endpoint_0);

    $("body").append(newLocallyScript);

    // $("body #lcly-script-0").attr('src',lcly_endpoint_0);

    let lcly_switchlive_events_endpoint_0 = `https://${company_domain}.locally.com/switchlive/switchlive-events.js?company_id=${company_id}`;
	let lcly_switchlive_endpoint_0 = `https://${company_domain}.locally.com/js/switchlive.js?company_id=${company_id}`;


	$('#lcly-script-switchlive-0').attr('src', lcly_switchlive_endpoint_0);
	$('#lcly-script-switchlive-events-0').attr('src',lcly_switchlive_events_endpoint_0);
  }

  });
};
