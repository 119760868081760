import $ from 'jquery';
import 'parsleyjs';
import 'parsleyjs/dist/i18n/fr';

export const FormValidator = () => {
    $(document).ready(() => {
        const langCode = $("#language-code").val();
        window.Parsley.addValidator('pastdate', {
            requirementType: 'string',
            validateString: function(value) {
              const inputDate = new Date(value);
              const today = new Date();
              today.setHours(0, 0, 0, 0); // Reset hours to compare only dates
          
              // Check if input date is less than or equal to today's date
              return inputDate <= today;
            },
            messages: {
              en: `Please enter a valid date.`,
              fr: `Veuillez entrer une date valide.`
              },
         
          });

        $("#warranty-form").parsley();
        $("#contact-form").parsley();
        $("#custom-form").parsley();
        $("#profile-form").parsley();

        if(langCode === 'fr-ca') {
            window.Parsley.setLocale('fr');
        }else {
            window.Parsley.setLocale('en');
        }
    });


};
